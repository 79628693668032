<template>
    <div class="container my-4">
        <div class="rounded alert alert-dismissible alert-light" role="alert" v-if="errorMessage">
            <div class="d-flex align-items-center">
                <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                Error: {{ errorMessage }}
            </div>
            <button type="button" class="ml-auto close" @click="errorMessage=''">&times;</button>
        </div>
        <div class="card">
            <div class="card-header d-flex align-items-center">
                Shipment {{ $route.params.id }}
                <ViewOrderButton
                    class="ml-auto"
                    v-if="shipment && shipment.order_id"
                    :orderId="shipment.order_id"/>
            </div>
            <div class="card-body">
                <Spinner v-if="loading" size="60" line-fg-color="gray" />
                <div v-if="!loading && shipment" class="row">
                    <div class="row col">
                        <ul class="list-group col">
                            <li class="list-group-item p-0" style="border: none">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .25rem 1rem; width: 7.5rem" class="d-flex justify-content-end">
                                            <h5 class="m-0">Shipping</h5>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none" v-if="shipment.shipengine_id">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 7.5rem" class="d-flex justify-content-end">
                                            <small>From</small>
                                        </div>
                                    </div>
                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                        <small v-if="shipFrom.company_name">{{ shipFrom.company_name }}</small>
                                        <small>{{ shipFrom.address_line1 }}</small>
                                        <small v-if="shipFrom.address_line2">{{ shipFrom.address_line2 }}</small>
                                        <small>
                                            {{ shipFrom.city_locality }},
                                            {{ shipFrom.state_province }}
                                            {{ shipFrom.postal_code }}
                                        </small>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none" v-if="shipment.shipengine_id">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 7.5rem" class="d-flex justify-content-end">
                                            <small>To</small>
                                        </div>
                                    </div>
                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                        <small>{{ shipTo.name }}</small>
                                        <small v-if="shipTo.company_name">{{ shipTo.company_name }}</small>
                                        <small>{{ shipTo.address_line1 }}</small>
                                        <small v-if="shipTo.address_line2">{{ shipTo.address_line2 }}</small>
                                        <small>
                                            {{ shipTo.city_locality }},
                                            {{ shipTo.state_province }}
                                            {{ shipTo.postal_code }}
                                        </small>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 7.5rem" class="d-flex justify-content-end">
                                            <small>Date</small>
                                        </div>
                                    </div>
                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                        <small>{{ formatDate(shipment.created_at) }}</small>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 7.5rem" class="d-flex justify-content-end">
                                            <small>Carrier</small>
                                        </div>
                                    </div>
                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                        <small>{{ shipment.carrier.name }}</small>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none" v-if="shipment.shipengine_id">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 7.5rem" class="d-flex justify-content-end">
                                            <small>Tracking #</small>
                                        </div>
                                    </div>
                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                        <small><a :href="shipment.tracking_url" target="_blank">{{ shipment.tracking_number }}</a></small>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none" v-if="shipment.shipengine_id">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 7.5rem" class="d-flex justify-content-end">
                                            <small>Label Actions</small>
                                        </div>
                                    </div>
                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                        <ShipmentLabelButtons v-if="shipment.label_url"
                                                              :shipment="shipment"
                                                              @error-message="errorMessage = $event"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="col" v-if="shipment.packages.length">
                            <li class="list-group-item p-0" style="border: none">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .25rem 1rem; width: 6.5rem" class="d-flex justify-content-end">
                                            <h5 class="m-0">Packages</h5>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none" v-for="shipment_package in shipment.packages">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 6.5rem" class="d-flex justify-content-end">
                                            <small>1 x</small>
                                        </div>
                                    </div>
                                    <div style="margin: 0 1rem" class="d-flex flex-column">
                                        <ul class="list-group">
                                            <li class="list-group-item p-0" style="border: none">
                                                <div class="d-flex flex-row">
                                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                                        <div style="margin: .5rem 1rem; width: 1.5rem" class="d-flex justify-content-end">
                                                            <small>Length</small>
                                                        </div>
                                                    </div>
                                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                                        <small>{{ shipment_package.dimensions.length + ' ' + pluralize(shipment_package.dimensions.unit) }}</small>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item p-0" style="border: none">
                                                <div class="d-flex flex-row">
                                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                                        <div style="margin: .5rem 1rem; width: 1.5rem" class="d-flex justify-content-end">
                                                            <small>Width</small>
                                                        </div>
                                                    </div>
                                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                                        <small>{{ shipment_package.dimensions.width + ' ' + pluralize(shipment_package.dimensions.unit) }}</small>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item p-0" style="border: none">
                                                <div class="d-flex flex-row">
                                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                                        <div style="margin: .5rem 1rem; width: 1.5rem" class="d-flex justify-content-end">
                                                            <small>Height</small>
                                                        </div>
                                                    </div>
                                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                                        <small>{{ shipment_package.dimensions.height + ' ' + pluralize(shipment_package.dimensions.unit) }}</small>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="col" v-if="shipment.items.length">
                            <li class="list-group-item p-0" style="border: none">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .25rem 1rem; width: 5.5rem" class="d-flex justify-content-end">
                                            <h5 class="m-0">Items</h5>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item p-0" style="border: none" v-for="item in groupedItems">
                                <div class="d-flex flex-row">
                                    <div style="border-right: 1px solid rgba(0,0,0,.125)">
                                        <div style="margin: .5rem 1rem; width: 5.5rem" class="d-flex justify-content-end">
                                            <small>{{ groupedItems[item[0].product_name].length + ' x' }}</small>
                                        </div>
                                    </div>
                                    <div style="margin: .5rem 1rem" class="d-flex flex-column">
                                        <small>{{ item[0].product_name }}</small>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import _ from "lodash";
    import pluralize from "pluralize";
    import ShipmentLabelButtons from '../../components/shipments/ShipmentLabelButtons.vue';
    import {getShipment} from '../../services/ShipmentService';
    import Spinner from 'vue-simple-spinner';
    import OrderFulfillmentShipmentDetails from '../../components/shipments/OrderFulfillmentShipmentDetails.vue';
    import ViewOrderButton from '../../components/shared/ViewOrderButton';
    import OrderFulfillment from "../orders/OrderFulfillment";

export default {
    name: 'ShipmentDetails',
    components: {
        OrderFulfillment,
        Spinner,
        OrderFulfillmentShipmentDetails,
        ViewOrderButton,
        ShipmentLabelButtons
    },
    data() {
        return {
            shipment: null,
            loading: false,
            errorMessage: ''
        };
    },
    created() {
        this.getShipment();
    },
    computed: {
        shipTo() {
            return this.shipment.address_information.ship_to;
        },
        shipFrom() {
            return this.shipment.address_information.ship_from;
        },
        groupedItems() {
            return _.groupBy(this.shipment.items, item => item.product_name);
        }
    },
    methods: {
        async getShipment() {
            try {
                this.loading = true;
                const response = await getShipment(this.$route.params.id);
                this.shipment = response.data;
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        formatDate(date) {
            return moment(date).format('ll');
        },
        pluralize(string) {
            return pluralize(string);
        }
    }
};
</script>

<style scoped>
    h5 {
        font-size: 1rem;
    }
</style>
